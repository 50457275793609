<template>
  <div v-if="objUser">
    <!-- profileimg -->
    <div class="profileimg">
      <img src="../assets/jackpotaruba.png" />
      <div class="_name">
        {{ getUserName() }}
      </div>
    </div>
    <!-- profileimg -->

    <!-- menulink -->
    <div class="menulinks">
      <ul>
        <li>
          <router-link :to="{ name: 'Home' }">
            <span class="icon-homes">
              <font-awesome-icon icon="fa-solid fa-home" />
            </span> 
            <div>{{ $t("BUY_NOW") }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="profile">
            <span class="user-icon">
              <font-awesome-icon icon="fa-solid fa-user" />
            </span>
            <div>{{ $t("PROFILE") }}</div></router-link
          >
        </li>
        <li>
          <router-link to="wallet">
            <span class="wallet-card">
              <font-awesome-icon icon="fa-solid fa-wallet" />
            </span>
            <div>{{ $t("MY_WALLET") }}</div>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'MyBets' }">
            <span class="bet-icon">
              <font-awesome-icon icon="fa-solid fa-coins" />
            </span>
            <div>{{ $t("MY_BETS") }}</div>
          </router-link>
        </li>
        <li>
          <router-link to="cart">
            <span class="bell-icon">
              <font-awesome-icon icon="fa-solid fa-cart-shopping" />
            </span>
            <div>My Cart</div>
          </router-link>
        </li>
        <li class="logout">
          <b-button
            @click="logout"
            class="logoutbtn _lrdevice"
            expanded
            type="is-primary"
            >{{ $t("LOGOUT") }}</b-button
          >
          <b-button
            @click="logout"
            class="logoutbtn _smdevice"
            expanded
            type="is-primary"
          >
            <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
            <div>{{ $t("LOGOUT") }}</div>
          </b-button>
        </li>
      </ul>
    </div>
    <!-- menulink -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  components: {},
  computed: {
    ...mapGetters(["objUser", "arrLines"]),
  },
  methods: {
    getUserName() {
      try {
        let { first_name, last_name } = this.objUser;
        return first_name + " " + last_name;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    logout() {
      this.$store.commit("logout");
      this.$utils.emitWCEvent(this, "pick-numbers");
      this.$router.replace({ name: "Home" });
    },
  },
};
</script>
