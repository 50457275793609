<template>
  <div>
glayout
    <div class="mainbody notloggedin">
      <div class="maincolumns">
        <div class="contentdiv lotterydiv">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style></style>
