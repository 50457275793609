import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import MainLayout from "../layouts/MainLayout.vue";
import GamesListLayout from "../layouts/GamesListLayout.vue";

export const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/games-list",
      name: "",
      component: GamesListLayout,
      children: [
        {
          path: "",
          name: "GamesList",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/GamesList.vue"),
        },
      ],
    },
    {
      path: "/",
      name: "",
      component: MainLayout,
      children: [
        {
          path: "",
          name: "Home",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
        },

        {
          path: "profile",
          name: "Profile",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Profile.vue"),
        },
        {
          path: "cart",
          name: "Cart",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Cart.vue"),
        },

        {
          path: "wallet",
          name: "Wallet",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Wallet.vue"),
        },

        {
          path: "deposit",
          name: "Deposit",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Deposit.vue"),
        },

        {
          path: "withdrawal",
          name: "Withdrawal",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Withdrawal.vue"),
        },

        {
          path: "notifications",
          name: "Notifications",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Notifications.vue"),
        },
        {
          path: "forgot-password",
          name: "ForgotPassword",
          component: () =>
            import(
              /* webpackChunkName: "home" */ "../views/ForgotPassword.vue"
            ),
        },
        {
          path: "forgot-password2",
          name: "ForgotPassword2",
          component: () =>
            import(
              /* webpackChunkName: "home" */ "../views/ForgotPassword2.vue"
            ),
        },
        {
          path: "my-bets",
          name: "MyBets",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/MyBets.vue"),
        },

        {
          path: "checkout",
          name: "CheckoutView",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/CheckoutView.vue"),
        },

        {
          path: "order-confirmation",
          name: "OrderConfirmation",
          component: () =>
            import(
              /* webpackChunkName: "home" */ "../views/OrderConfirmation.vue"
            ),
        },

        {
          path: "sign-in",
          name: "SignIn",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/SignIn.vue"),
        },

        {
          path: "register-page",
          name: "RegisterPage",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/RegisterPage.vue"),
        },

        // {
        //   path: "login",
        //   name: "LogIn",
        //   props: true,
        //   component: () =>
        //     import(/* webpackChunkName: "home" */ "../views/LogIn.vue"),
        // },

        {
          path: "sign-in2",
          name: "SignIn2",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/SignIn2.vue"),
        },

        {
          path: "sign-up",
          name: "SignUp",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/SignUp.vue"),
        },
        // {
        //   path: "sign-up2",
        //   name: "SignUp",
        //   props: true,
        //   component: () =>
        //     import(/* webpackChunkName: "home" */ "../views/SignUpOld.vue"),
        // },
        // {
        //   path: "register",
        //   name: "Register",
        //   props: true,
        //   component: () =>
        //     import(/* webpackChunkName: "home" */ "../views/Register.vue"),
        // },
        {
          path: "register2",
          name: "Register2",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Register2.vue"),
        },
        {
          path: "logout",
          name: "Logout",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/LoggedOff.vue"),
        },
        {
          path: "logged-off",
          name: "LoggedOff",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/LoggedOff.vue"),
        },
        {
          path: "redirector",
          name: "Redirector",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Redirector.vue"),
        },
        {
          path: "checkout-deposit",
          name: "CheckoutDeposit",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "home" */ "../views/CheckoutDeposit.vue"
            ),
        },
        // {
        //   path: "reset-password",
        //   name: "ResetPassword",
        //   component: () =>
        //     import(/* webpackChunkName: "home" */ "../views/ResetPassword.vue"),
        // },
        {
          path: "reset-password2",
          name: "ResetPassword",
          component: () =>
            import(
              /* webpackChunkName: "home" */ "../views/ResetPassword2.vue"
            ),
        },
        {
          path: "verify-email",
          name: "VerifyEmail",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/VerifyEmail.vue"),
        },
        {
          path: "sign-up-done",
          name: "SignUpDone",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/SignUpDone.vue"),
        },
        {
          path: "diag",
          name: "Diag",
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/Diag.vue"),
        },

        {
          path: "pick",
          name: "PickNumbers",
          props: true,
          component: () =>
            import(/* webpackChunkName: "home" */ "../views/PickNumbers.vue"),
        },
      ],
    },
  ],
});
