import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";
const PRICE_PER_UNIT = 2;

function emitCartUpdate(state) {
  console.log("emitting");

  console.log("state is ", state);
  console.log("carttotal is");
  let intNumLines = state.arrLines.length;

  let intCartTotal = intNumLines * PRICE_PER_UNIT;

  Vue.prototype.$utils.emitWCEvent2(Vue.prototype.$root, "cartUpdate", {
    intNumItems: state.arrLines.length,
    intCartTotal,
  });

  console.log("emitted");
}

function getLottoModel(strLottoId) {
  let obj = {
    strLottoId: strLottoId,
    arrLines: [
      {
        arrNumbers: [],
      },
    ],
  };

  return obj;
}

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    arrLottoConfigs: [
      {
        strLottoId: "pick3",
        intPricePerLine: 2,
        strCurrency: "USD",
        intNumbersToPick: 3,
      },
      {
        strLottoId: "pick4",
        intPricePerLine: 3,
        strCurrency: "USD",
        intNumbersToPick: 4,
      },
    ],
    arrLottos: [
      {
        strLottoId: "noop",
        arrLines: [
          {
            arrNumbers: [],
          },
        ],
      },
    ],

    arrLines: [
      {
        strLottoId: "",
        arrNumbers: [],
        blnDisabled: false,
      },
    ],
    objSignInData: { objUser: {}, strToken: "" },
    objWalletStats: {},
    objCredentials: {},
    intNumDraws: 1,
    intStakeAmount: 10,
    arrBets: [],
  },
  getters: {
    arrLottoConfigs: (state) => {
      try {
        return state.arrLottoConfigs;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    arrLottos: (state) => {
      try {
        return state.arrLottos;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    getLottoLines: (state, getters) => (strLottoId) => {
      let arr = [];

      let objLotto = state.arrLottos.find(
        (objLotto) => objLotto.strLottoId == strLottoId
      );
      if (!objLotto) {
        return [];
      }
      console.log("strLottoId" + strLottoId);
      let objLottoConfig = getters.getLottoConfig(strLottoId);
      if (!objLottoConfig) {
        return [];
      }

      let arrLines = objLotto.arrLines;
      for (let i = 0; i < arrLines.length; i++) {
        let objLine = arrLines[i];
        objLine.strLottoId = strLottoId;
        objLine.intIndex = i;
        objLine.intNumbersToPick = objLottoConfig.intNumbersToPick;
        objLine.intPricePerLine = objLottoConfig.intPricePerLine;
        if (objLine.strStatus != "a") {
          // continue;
        }

        arr.push(objLine);
      }

      return arr;
    },

    arrAllLottoLines: (state, getters) => {
      let arr = [];
      for (let objLotto of state.arrLottos) {
        let { strLottoId } = objLotto;
        if (strLottoId == "noop") {
          continue;
        }
        let arrLottoLines = getters.getLottoLines(strLottoId);

        // console.log("before", arrLottoLines);
        // arrLottoLines = arrLottoLines.filter((objLine) => {
        //   return objLine.blnDummy == false;
        // });
        // console.log("after", arrLottoLines);

        arr = arr.concat(arrLottoLines);
      }

      return arr;
    },

    getLottoConfig: (state) => (strLottoId) => {
      state.arrLottoConfigs = [
        {
          strLottoId: "pick3",
          intPricePerLine: 2,
          strCurrency: "USD",
          intNumbersToPick: 3,
        },
        {
          strLottoId: "pick4",
          intPricePerLine: 3,
          strCurrency: "USD",
          intNumbersToPick: 4,
        },
      ];

      let objLottoConfig = state.arrLottoConfigs.find(
        (obj) => obj.strLottoId == strLottoId
      );

      return objLottoConfig;
    },

    strToken: (state) => {
      try {
        return state.objSignInData.strToken;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    objUser: (state) => {
      try {
        return state.objSignInData.objUser;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    blnIsUserLoggedIn: (state) => {
      try {
        if (state.objSignInData.objUser.player_id) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },
    fltCartValue: (state) => {
      try {
        return state.arrLines.length * 2;
      } catch (e) {
        console.log(e);
      }

      return 0;
    },
    objWalletStats: (state) => {
      try {
        return state.objWalletStats;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    intCartTotal: (state, getters) => {
      let intTotal = 0;
      let arrLines = getters.arrAllLottoLines;
      arrLines = arrLines.filter((objLine) => objLine.strStatus == "a");
      try {
        for (let objLine of arrLines) {
          intTotal += objLine.intPricePerLine;
        }
        return intTotal;
      } catch (e) {
        console.log(e);
      }
      return "";
    },
    objCredentials: (state) => {
      try {
        return state.objCredentials;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    arrBets: (state) => {
      try {
        return state.arrBets;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    arrCompletedBets: (state) => {
      try {
        //only return non pending
        let arr = [];
        let arrBets = state.arrBets;
        for (let i = 0; i < arrBets.length; i++) {
          let objBet = arrBets[i];
          objBet.intIndex = i;
          if (objBet.strStatus == "completed") {
            arr.push(objBet);
          }
        }
        return arr;
      } catch (e) {
        console.log(e);
      }
      return [];
    },
  },

  mutations: {
    emitState(state) {
      console.log("mutation");
      emitCartUpdate(state);
    },
    resetState(state) {
      state.arrLottos = {
        noop: {
          arrLines: [
            {
              arrNumbers: [],
            },
          ],
          strLottoId: "",
          blnDisabled: false,
        },
      };

      state.objSignInData = { objUser: {}, strToken: "" };
      emitCartUpdate(state);
    },
    signIn(state, objSignInData) {
      try {
        console.log(objSignInData);

        let { objUser, strToken } = objSignInData;

        state.objSignInData.objUser = objUser;
        state.objSignInData.strToken = strToken;
      } catch (e) {
        console.log(e);
      }
    },

    addToCart(state, { strLottoId, objLine }) {
      try {
        let objLotto = state.arrLottos.find(
          (obj) => obj.strLottoId == strLottoId
        );

        if (!objLotto) {
          objLotto = getLottoModel(strLottoId);
          state.arrLottos.push(objLotto);
        }

        objLotto.arrLines.push(objLine);

        emitCartUpdate(state);

        //Vue.set(state.data, key, value)
      } catch (e) {
        console.log(e);
      }
    },

    cleanupCart(state) {
      try {
        let arrNewBets = [];

        let arrBets = state.arrBets;
        for (let objBet of arrBets) {
          if (objBet.strStatus == "completed") {
            arrNewBets.push(objBet);
          }
        }
        state.arrBets = arrNewBets;
      } catch (e) {
        console.log(e);
      }
    },

    deleteLine(state, { intBetIndex, intLineIndex }) {
      try {
        let objBet = state.arrBets[intBetIndex];
        let { arrLines } = objBet;
        arrLines.splice(intLineIndex, 1);
        emitCartUpdate(state);
      } catch (e) {
        console.log(e);
      }
    },

    deleteBet(state, intIndex) {
      try {
        state.arrBets.splice(intIndex, 1);
        emitCartUpdate(state);
      } catch (e) {
        console.log(e);
      }
    },

    setNumbers(state, { intBetIndex, intLineIndex, arrNumbers }) {
      try {
        let objBet = state.arrBets[intBetIndex];
        let objLine = objBet.arrLines[intLineIndex];
        objLine.arrNumbers = arrNumbers;
      } catch (e) {
        console.log(e);
      }
    },
    setLine(state, { strLottoId, intIndex, objLine }) {
      try {
        let objLotto = state.arrLottos.find(
          (objLotto) => objLotto.strLottoId == strLottoId
        );
        objLotto.arrLines[intIndex] = objLine;
      } catch (e) {
        console.log(e);
      }
    },

    addBet(state, objBet) {
      try {
        //status is added
        objBet.blnExpand = false;
        state.arrBets.push(objBet);
      } catch (e) {
        console.log(e);
      }
    },
    updateBet(state, { intBetIndex, objBet }) {
      try {
        state.arrBets[intBetIndex] = objBet;
      } catch (e) {
        console.log(e);
      }
    },
    setBetStatus(state, { intBetIndex, strStatus }) {
      try {
        state.arrBets[intBetIndex].strStatus = strStatus;
      } catch (e) {
        console.log(e);
      }
    },

    emptyCart(state) {
      try {
        state.arrLottos = [];
        state.objLottos = {};
        state.arrBets = [];
        emitCartUpdate(state);
      } catch (e) {
        console.log(e);
      }
    },
    logout(state) {
      try {
        state.objSignInData = { objUser: {}, strToken: "" };
        state.arrLines = [];
      } catch (e) {
        console.log(e);
      }
    },
    setWalletStats(state, objWalletStats) {
      try {
        state.objWalletStats = objWalletStats;
      } catch (e) {
        console.log(e);
      }
    },

    setCredentials(state, objCredentials) {
      try {
        state.objCredentials = objCredentials;
      } catch (e) {
        console.log(e);
      }
    },
  },
  actions: {},
  modules: {},
});
