import utils from "../modules/utils";
//import { accessToken } from "../composables/auth";
import axios from "axios";
const utilsPlugin = {
  install(Vue, objOptions) {
    let { store, router } = objOptions;

    utils.showSnackbar = (strMessage) => {
      //alert(strMessage);
      //return
      Vue.prototype.$buefy.snackbar.open({
        duration: 5000,
        message: strMessage,
        container: "div#app",
        position: "is-bottom-right",
      });
    };
    utils.CopyToClipboard = (e) => {
      navigator.clipboard.writeText(e.innerText);
    };
    utils.dropDownOptions = {
      showDialCodeInList: true,
      showDialCodeInSelection: true,
      showFlags: true,
    };
    utils.inputOptions = {
      showDialCode: false,
      placeholder: "Enter your phone number",
      required: true,
      autocomplete: "off",
      type: "tel",
    };

    utils.getRandomString = (intLength) => {
      return Array(intLength + 1)
        .join((Math.random().toString(36) + "00000000000000000").slice(2, 18))
        .slice(0, intLength);
    };
    utils.getUserRequestStatus = (strVal) => {
      let obj = utils.arrUserRequestStatus.find((obj) => obj.value == strVal);
      if (obj) return obj.label;
      return "";
    };
    utils.arrUserRequestStatus = [
      {
        label: "Submitted",
        value: "r",
      },
      {
        label: "Approved",
        value: "a",
      },
      {
        label: "Declined",
        value: "d",
      },
      {
        label: "None",
        value: "n",
      },
    ];
    utils.getUserRequestStatusColor = (strVal) => {
      let str = "";
      switch (strVal) {
        case "r":
          str = "warning";
          break;
        case "a":
          str = "positive";
          break;
        case "d":
          str = "negative";
          break;
        case "n":
          str = "negative";
          break;
      }
      return str;
    };
    utils.getUploadedFileUrl = (strFileName) => {
      return (
        "https://s3.eu-west-1.amazonaws.com/imprexis-banners-bucket/" +
        strFileName
      );
    };

    utils.getNewLine = (strGroup) => {
      // let arrRandNums = getRandomNumbers(this.intNumbersToPick, 10);
      let arrNumbers = [];
      // for (let i = 0; i < arrRandNums.length; i++) {
      //   let intNumber = arrRandNums[i];
      //   arrNumbers.push(intNumber - 1);
      // }

      let strStatus = "x";

      return { arrNumbers, strGroup, strStatus };
    };

    //intNumbersToPick is the numbers needed to be picked for
    //this lotto line
    utils.isLineOK = (objLine, intNumbersToPick) => {
      if (objLine.arrNumbers.length == 0) {
        return false;
      }
      if (objLine.arrNumbers.length < intNumbersToPick) {
        return false;
      }

      for (let intNumber of objLine.arrNumbers) {
        if (intNumber == null) {
          return false;
        }
      }

      return true;
    };

    utils.arrKYCType = [
      {
        label: "ID or Passport",
        value: "ID",
      },
      {
        label: "Webcam photo",
        value: "WEBC",
      },
      {
        label: "Proof of Address",
        value: "POA",
      },
      {
        label: "Source of funds",
        value: "SOF",
      },
      {
        label: "Credit Card",
        value: "CC",
      },
    ];

    utils.showErrorNotice = (strMessage) => {
      utils.showSnackbar(strMessage);
    };

    utils.handleS3Upload = async (file) => {
      let objPresignedPost = await utils.getSignedUrl();
      if (!objPresignedPost) {
        throw new Error("Presigned url not available");
      }
      const formData = new FormData();
      formData.append("Content-Type", file.type);

      Object.entries(objPresignedPost.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", file);

      const instance = axios.create();
      const res = await instance.post(objPresignedPost.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        transformRequest: (data, headers) => {
          delete headers.common["Authorization"];
          return data;
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.fltPercentCompleted = percentCompleted;
          // console.log(percentCompleted / 100);
        },
      });
      this.blnImgUploading = false;

      // console.log('The headers are ', res.headers);
      const location = res.headers.location; // get the final url of our uploaded image
      let strImgUrl = decodeURIComponent(location);

      if (strImgUrl) {
        let arrTemp = strImgUrl.split("/");
        // console.log('arrTemp is', arrTemp)
        if (arrTemp.length > 1) {
          let strImgName = arrTemp[arrTemp.length - 1];
          this.$emit("uploaded", strImgName);
          this.$emit("input", strImgName);
        }
      } else {
        window.alert("Image could not be uploaded, please try again!");
      }
    };

    utils.getSignedUrl = async () => {
      let strUrl = "/u/gsu";
      let objResponse = await this.$myAxios.get(strUrl);
      if (objResponse.blnOK) {
        return objResponse.obj;
      }
      return null;
    };

    utils.getLottosConfigs = async (strLottoId) => {
      let objRequest = {
        arrLottoIds: ["pick3", "pick4"],
      };

      let objResponse = await Vue.prototype.$myAxios.post(
        "/lottos-configs",
        objRequest
      );
      console.log(objResponse);

      return objResponse;
    };

    utils.getLottoConfig = async (strLottoId) => {
      let objRequest = {
        arrLottoIds: ["pick3", "pick4"],
      };

      let objResponse = await Vue.prototype.$myAxios.post(
        "/lottos-configs",
        objRequest
      );
      console.log(objResponse);

      return objResponse;
    };
    Vue.prototype.$utils = utils;
  },
};

export default utilsPlugin;
