import fa from "vee-validate/dist/locale/fa.json";

export default {
  BUY_NOW: "خريد بليط",
  ONE_PENCIL_THOUSANDS_CHANGES: "یک مداد، یک شانس، هزاران تغییر!",
  HOME_DONATE_PENCIL_HEADING: "با اهدا یک مداد در قرعه کشی لوتوچی شرکت کنید",
  HOME_DONATE_PENCIL_TEXT:
    "قسمتی از هزینه هر مدادی که می‌خرید به خیریه های همکار اهدا می‌شود تا به دست شخص نیازمند برسد",
  HOME_DONATE_PENCIL_TEXT_2:
    "با خرید هر مداد شما می توانید یک بلیط برای شرکت در قرعه کشی ثبت کنید و محدودیتی برای تعداد خرید شما وجود ندارد",
  Note: "",
  WELCOME: "به لوتوچی خوش آمديد",
  PICK_NUMBERS: "انتخاب شماره های بليط",
  PROCEED_CART: "مرحله بعد",
  PROCEED_CHECKOUT: "تسویه حساب",
  BACK: "بازگشت",
  RANDOMIZE_ALL: "انتخاب تصادفی همه",
  CLEAR_ALL: "پاک کردن همه",
  ADD_LINE: "اضافه کردن بليط",
  LINE_NO: "بليط شماره",
  MY_SHOPPING_CART: "سبد بليط های من",
  PENCIL: "شماره",
  TICKET: "بليط",
  TICKET_NUMBERS: "شماره های بليط",
  CHECKOUT_MSG:
    'با کلیک بر روی دکمه "تسویه حساب"، با قوانین و شرایط  ما موافقت می کنید. هزینه تمام تراکنش ها به دلار تتر آمریکا می باشد و هزینه انتقال به صورت جداگانه توسط شبکه محاسبه می شود',

  CHECKOUT_MSG_1: 'با کلیک بر روی دکمه "تسویه حساب"، با',
  CHECKOUT_MSG_2: "قوانین و شرایط",
  CHECKOUT_MSG_3:
    "ما موافقت می کنید. هزینه تمام تراکنش ها به دلار تتر آمریکا می باشد و هزینه انتقال به صورت جداگانه توسط شبکه محاسبه می شود",

  DELETE: "حذف",
  PRICE: "قيمت",
  PAY: "پرداخت",
  CHECKOUT: "صفحه پرداخت",
  MY_WALLET: "کيف پول من",
  AMOUNT: "مبلغ",
  TRANSACTIONS: "تراکنش ها",
  ID: "شناسه تراکنش",
  DATE: "تاريخ",
  TYPE: "نوع",
  CURRENCY: "نوع ارز",
  STATUS: "وضعيت",
  CREDIT: "واريز",
  EDIT: "ویرایش",
  FIELD_REQUIRED: "لطفا اطلاعات خود را وارد کنید",

  SUBMIT: " ایجاد رمز",
  SUBMIT_ON_FORGOT_PASSWORD: "تکرار رمز عبور",
  INSUFFICIENT_FUNDS: "موجودی شما کافی نیست",
  INSUFFICIENT_FUNDS_MSG:
    "کاربر عزیز لطفا برای  ثبت بلیط در قرعه کشی حساب خود را شارژ کنید",
  PRICE_AMOUNT: "مبلغ انتخابی شما",
  CANCELED: "لغو شد",
  PAY_AMOUNT: "تبدیل مبلغ به رمز ارز",
  DEPOSIT: "شارژ حساب",
  DEPOSIT_NOW: "شارژ حساب",
  PENDING: "در انتظار انجام",
  WITHDRAW_AMOUNT: "برداشت مبلغ",
  COPY_THE_PAY_WALLET_ADDRESS:
    "به سادگی می توانید آدرس کیف پول پرداخت را از اینجا کپی کنید",
  COPY: "کپی",
  DONE: "اتمام",

  WITHDRAW_AMOUNT_MSG: "لطفا مبلغ برداشت را وارد کنيد",
  WALLET_ADDRESS_MSG: " آدرس کيف پول خود را اينجا وارد کنيد",
  WITHDRAW: "برداشت مبلغ",
  MY_TICKETS: "بليط های من",
  DRAW_DATE: "تاريخ قرعه کشی",
  BET_ID: "شناسه",
  COST: "هزينه",
  WINNINGS: "جوایز",
  NEXT: "مرحله بعد",
  CART_TOTAL: "مجموع خريد",
  HOME: "صفحه اصلی",

  CREATE_ACCOUNT: "ايجاد حساب لوتوچی",
  FIRST_NAME: "نام",
  WITHDRAW_PRIZES: "برداشت از جوايز",
  LAST_NAME: "نام خانوادگی",

  DEPOSIT_AMOUNT_MSG:
    "به سادگی می توانید یکی از مبالغ زیر را انتخاب کنید یا داخل کادر آبی رنگ مبلغ مورد نظر خود را وارد کنید",

  SIGNUP_DATA:
    " اطلاعات شخصی شما برای پشتیبانی از شما در سراسر این وب سایت، مدیریت دسترسی به حساب شما و سایر اهدافی که در حفظ حریم خصوصی ما توضیح داده شده است، استفاده خواهد شد",
  FORGOT_PASSWORD: "رمز عبور خود را فراموش کرده ام",
  LOGIN: "ورود",
  DETAIL: "جزِئیات",
  NEXT_PAGE: "مرحله بعد",
  PREVIOUS_PAGE: "بازگشت",
  PAGE: "صفحه",
  CURRENT_PAGE: "صفحه فعلی",
  LOGOUT: "خروج",
  YOUR: "من",
  WALLET: "کیف پول ",
  CART_EMPTY:
    'هیچ بلیطی در سبد شما وجود ندارد. لطفاً از دکمه "اضافه کردن بلیط" برای ایجاد آن استفاده کنید',
  PASSWORD_RESET_EMAIL:
    "لطفا ایمیل خود را کنترل کنید. ما یک ایمیل با لینک ایجاد رمز عبور جدید برای شما ارسال کرده ایم",
  PROFILE: "مشخصات من",
  BET_PLACED_MSG: "بلیط شما با موفقیت ثبت شد",
  INCREASE_YOUR_CHANCES:
    "با خرید بلیط بیشتر شانس خودتون رو برای بردن $30,000 افزایش بدین",
  ONE_TICKET_ONE_CHANCE: "یک بلیط جدید یک شانس جدیده",
  ALL_DEPOSITS_SUBJECT_NETWORK_FEES:
    "شبکه رمز ارز هزینه ای رو برای انجام انتقال از مبلغ انتخابی شما کم می کنه که فقط بستگی به زمان انجام انتقال داره و بین 0.4 تا 1.5 USD تغییر می کنه و مبلغ انتقال در اون هیچ تاثیری نداره. مبلغ بیشتری رو شارژ کنید تا هزینه انتقال رو یک بار پرداخت کنید. تماشای ویدیوی آموزشی",
  GENERATED_WALLET_ADDRESS_VALID_24:
    "توجه: این آدرس کیف پول رمز ارز مخصوص شارژ حساب لوتوچی شما ایجاد شده و فقط تا 22 ساعت اعتبار دارد.لطفا پس از اتمام اعتبار درخواست جدید ثیت کنید.تماشای ویدیوی آموزشی",
  WITHDRAWALS_TAKE_72_HRS:
    "دقت کنید آدرس فقط برای واریز USDو فقط در شبکه TRC20 رو وارد کنید. در غیر این صورت پول شما در شبکه رمز ارز گم خواهد شد. واریز مبلغ بعد از انجام مراحل لازم برای تایید اصالت درخواست وطی 3 روز کاری انجام می شود.",
  BE_CAREFUL_TO_ENTER_THE_ADDRESS:
    "دقت کنید آدرس فقط برای واریز USDو فقط در شبکه TRC20 رو وارد کنید. در غیر این صورت پول شما در شبکه رمز ارز گم خواهد شد. واریز مبلغ بعد از احراز هویت وطی 3 روز کاری انجام می شود.",
  PLAY_AGAIN: "خرید بلیط",
  ORDER: "سفارش",
  ORDER_CONFIRMATION: "تایید سفارش",
  HOW_TO_ADD_CREDIT: "چطور حسابم را شا رژ کنم؟  ",
  WATCH_ADD_CREDIT_VIDEO_MSG:
    "لطفا برای اطمینان از انجام صحیح پرداخت  ابتدا حتما ویدیو راهنمای  شارژ حساب را تماشا کنید",
  WITHDRAWAL_REQUEST_SUCCESS:
    "با تشکر.درخواست برداشت شما دریافت شد و به زودی به آن رسیدگی می شود",
  WITHDRAWAL_REQUEST_ERROR:
    "لطفاً جزئیات را بررسی کنید و دوباره امتحان کنید. برای ارسال درخواست برداشت خطایی روی داد",
  CREATE_ANOTHER_WITHDRAWAL:
    "برای ایجاد درخواست برداشت جدید، لطفا دکمه زیر را فشار دهید",
  WITHDRAWAL_OF_PRIZES: "برداشت جوایز",
  NUMBERS_NOT_SELECTED_MSG:
    "شما شماره های خود را انتخاب نکرده اید. لطفا برای انتخاب آنها اینجا کلیک کنید",
  ENTER_A_NUMBER: "عدد وارد کنید",
  VALUE_MUST_BE_LESS_THAN: "برای خرید بیشتر از 10 عدد  چند بار خرید کنید",
  GAME: "بازی",
  PLACED_DATE: "تاریخ ثبت",
  DATA_NOT_AVAILABLE: "اطلاعات موجود نیست",

  COMBINATION: "ترکيب",
  SINGLE_LOTTERY_BET_AMOUNT: "مبلغ شرکت در قرعه کشی",
  TOTAL_WINNINGS: "مبلغبرنده شده",
  TICKET_NUMBER: "شماره بليط",
  DRAW_NUMBER: "شماره قرعه کشی",

  SELLER: "برگزار کننده",
  RESULT: "نتیجه قرعه ‌کشی",
  MY_NUMBERS: "شماره های من",
  LOADING: "در حال بارگزاری",
  TOTAL_COST: "مجموع هزينه",
  TOTAL: "موجودی",

  PLACED: "ثبت شده",
  LOST: "برنده نشده",
  WON: "برنده شده",
  PURCHASE_DATE: "تاريخ پرداخت",

  ERROR_EMAIL_PASSWORD: "نام کاربری يا رمزعبور اشتباه است",
  OK: "متوجه شدم",
  DEBIT: "برداشت",
  LOTTO_TICKET: "خريد بليط",
  REFUND: "برگشت جايزه",
  WINNING: "جايزهبرنده شده",
  SUCCESSFUL: "موفق",

  GET_GUIDANCE_HOW_TO_CREDIT:
    "لطفا برای اطمینان از انجام صحیح انتقال ابتدا حتما این ویدیو رو تماشا کنید",
  GET_GUIDANCE_HOW_TO_WITHDRAW:
    "شما می توانید مبالغ بالای 50USD و تنها از جوایز خود را برداشت کنید. تماشای ویدیوی آموزشی",
  DEPOSIT_WARNING:
    "اخطار: در صورت عدم ارسال (USD) و در شبکه (TRC20) پول شما از بین خواهد رفت. اگر کیف پول ندارید، می توانید از کیف پول دیگران برای واریز استفاده کنید. در نظر داشته باشید که برای کارمزد تراکنش مبلغ بیشتری واریز کنید. لطفا برای کسب اطلاعات بیشتر ویدیو را تماشا کنید",
  EMAIL_ALREADY_TAKEN: "این ایمیل حساب لوتوچی دارد",
  SELECT_SIX_NUMBERS: "لطفا برای هر بلیط شش عدد را انتخاب کنید",
  ACCOUNT_CREATED_MSG: "حساب لوتوچی شما ایجاد شد",
  ACCOUNT_CREATED_INST: "",
  ACCOUNT_CREATED_MSG_EMAIL:
    "حساب شما ایجاد شد و ما یک ایمیل برای تأیید حساب شما ارسال کردیم",
  ACCOUNT_CREATED_INST_EMAIL:
    "لطفاً دستورالعمل‌های موجود در ایمیل را برای تأیید دنبال کنید",

  fields: {
    email: "البريد الاليكتروني",
    password: "كلمة السر",
  },
  validations: fa,
  PASSWORD_RESET_LINK: "",
  CONFIRM_PASSWORD: "تکرار رمز عبور",
  TOKEN_EXPIRED: "لینک منقضی شده است. لطفا دوباره سعی کنید",
  TOKEN_EXPIRED_2: "درخواست رمز عبور",
  ACCOUNT_EMAIL_NOT_FOUND: "حسابی با این ایمیل وجود ندارد",
  WITHDRAWABLE: "قابل برداشت",

  SIGN_IN: "ورود",
  MOBILE_NUMBER: "شماره موبایل",
  PASSWORD: "رمز عبور",
  REPEAT_PASSWORD: "تکرار رمز عبور",
  SIGN_UP: "ثبت نام",

  SMS_REGISTRATION: "با شماره موبایل",
  EMAIL_REGISTRATION: "با آدرس ایمیل",
  SMS: "شماره موبایل",
  OR: "یا با استفاده از",
  NOT_REGISTERED_YET: "حساب لوتوچی ندارید؟",
  CREATE_ACCOUNT_AND_START: "ایجاد سریع حساب لوتوچی",
  START_WINNING: "",
  REGISTER: "ثبت نام",

  EMAIL: "ایمیل",
  SIGNUP_TC_START: "من با",
  SIGNUP_TC_COMMA: "و",
  SIGNUP_TC_TC: "شرایط و قوانین",
  SIGNUP_TC_COOKIE: "چگونگی کوکی های",
  SIGNUP_TC_AND: "",

  SIGNUP_TC_END:
    "وب سایت موافقم. تأیید می کنم که بیش از 16 سال سن دارم و موافقت می کنم که ارتباطات بیشتری از این وب سایت از طریق ایمیل و شماره تلفن دریافت کنم",
  CONTINUE: "ادامه",
  ALREADY_REGISTERED: "حساب لوتوچی دارید؟",
  LOGIN_HERE: "از اینجا وارد بشین",
  PLEASE_ENTER_THE_ADDRESS_OR_MOBILE:
    "لطفا شماره موبایل و یا آدرس ایمیلی که برای ثبت نام از آن استفاده کرده اید را وارد کنید",

  SEND_RESET_LINK: "ارسال لینک",
  REMOVE_THE_LEADING:
    "لطفا کد کشور را انتخاب کنید و شماره موبایل را بدون 0 اول آن وارد کنید",
  IM_NOT_ROBOT: "من روبات نیستم",
  SEND_OTP: "ارسال رمز یک بارمصرف",

  VERIFY_OTP: "تایید رمز",
  ENTER_CODE: "رمز یک بار مصرف",
  VERIFY: "ادامه",
  THE_OTP_IS_ONE_TIME: "رمز یک بار مصرف برای شما از طریق پیامک ارسال می شود",
  REMEMBER_ME: "مرا به خاطر بسپار",

  RESET_PASSWORD: "ایجاد رمز عبور جدید",
  INTRODUCE_NEW_PASSWORD: "لطفا رمز عبور جدید خود را وارد کنید",

  NAME: "اسم",
  FAMILY_NAME: "نام خانوادگی",
  PLEASE_MAKE_A_PAYMENT_USING_QR:
    "لطفا از QR کد زیر برای انجام انتقال استفاده کنید:",

  FORGOT_PASSWORD: "رمز عبور خود را فراموش کرده ام",
  EMAIL_ADDRESS: "آدرس ایمیل",
  AN_OTP_ONE_TIME_PASSWORD:
    "رمز یک بار مصرف برای شما از طریق پیامک ارسال می شود",
  YOU_HAVE_LOGGED_OUT: "لطفا دوباره وارد حسابتون بشید",
  AGAIN: "از نو",
  ACCOUNT: "  حساب لوتوچی",
  YOU_HAVE_SUCCESSFULLY_CHANGED_PASSWORD: "تغییر رمز عبور با موفقیت انجام شد",

  NOTIFICATIONS: "اطلاعیه",
  VERIFY_EMAIL: "تایید آدرس ایمیل",
  PLEASE_WAIT_ACCOUNT_VERIFYING:
    "لطفا صبر کنید. ما داریم حساب شما رو تایید می کنیم",
  YOUR_ACCOUNT_HAS_BEEN_VERIFIED:
    "حساب لوتوچی شما تایید شد. شما تا 5 ثانیه دیگه به صورت خودکار وارد حسابتون میشید",

  IF_YOUR_ARE_NOT_AUTO_SIGNED_IN: "اگر به صورت خودکار وارد حسابتون نشدید",
  ENTER_MOBILE_NUMBER_WITHOUT_ZERO:
    "توجه: شماره موبایل خودر را بدون صفر ابتدای آن وارد کنید",

  CLICK_HERE: "اینجا کلیک کنید",
  USERNAME: "نام کاربری",
  PASSWORDS_DONT_MATCH: "رمزهای عبور یکسان نیستند",
  ERROR_MOBILE_PASSWORD: "حسابی با این شماره موبایل ثبت نشده",
  NOT_ENOUGH_BALANCE: "موجودی جوایز شما کافی نیست.",
  ENTER_PHONE_NUMBER: "شماره موبایل خود را وارد کنید",
  NO_ITEMS_IN_CART: "سبد بلیط ‌های شما خالی است ",
  USER_NOT_FOUND: "این حساب وجود ندارد",
  WALLET_ADDRESS_COPIED: "آدرس کپی شد",
  MOBILE_ALREADY_TAKEN: "این موبایل حساب لوتوچی دارد",
  REPEAT_EMAIL: "تکرار آدرس ایمیل  ",
  REPEAT_MOBILE_NUMBER: "تکرار شماره موبایل",

  EMAILS_DONT_MATCH: "ایمیل ها یکسان نیستند",
  MOBILES_DONT_MATCH: "شماره های موبایل یکسان نیستند",
  ENTER_EMAIL: "آدرس ایمیل",
  PASSWORD_CHANGED: "رمز عبور ثبت شد",
  FAILED: "FAILED",

  E_VOUCHER_NUMBER: "e-Voucher Number",
  E_VOUCHER_ACTIVATION_CODE: "e-Voucher Activation Code",

  SEARCH_CRYPTO_EXCHANGE_OFFICES: "جستجوی صرافی های رمز ارز",
  SEARCH_PERFECT_MONEY_DEALERS: "جستجوی فروشندگان پرفکت مانی",
  SUCCESSFUL_EV_NUMBER: "پرداخت با موفقیت انجام شد",
  WATCH_THIS_VIDEO_HOW_TO_USE_PERFECT_MONEY:
    "این ویدیو رو تماشا کنید تا یاد بگیرید چطور از پرفکت مانی برای شارژ حسابتون استفاده کنید",

  PAY_WITH_PERFECT_MONEY: "پرداخت با پرفکت مانی",
  PAY_WITH_CRYPTO: "پرداخت با رمز ارز",
  INVALID_EV_NUMBER: "اطلاعات وارد شده درست نیست",
  TICKET_ID: "شناسه بلیط",
  WITHDRAW_AMOUNT_LIMIT: "حد اقل مبلغ برداشت 50 دلار است",
  ONLY_AMOUNT_ABOVE_4USD:"فقط برای مبالغ بالای ۴ دلار",
};
