import dayjs from "dayjs";
import axios from "axios";

const utils = {
  strVersionNo: "1.0",
  formatDateTime(strDateTime) {
    return dayjs(strDateTime).format("YYYY-MM-DD HH:mm");
  },
  emitWCEvent(ref, strEvent) {
    //ref is this
    ref.$root.$children[0].$emit(strEvent, { name: "Data" });
  },
  emitWCEvent2($root, strEvent, objData) {
    //ref is this
    $root.$children[0].$emit(strEvent, objData);
  },

  getRandomNumbers(intNumNumbers, intMaxNumber) {
    let array = Array(intMaxNumber)
      .fill()
      .map((_, index) => index + 1);

    var tmp,
      current,
      top = array.length;

    if (top)
      while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
      }
    array = array.slice(0, intNumNumbers);

    return array;
  },
  showSuccessNotice() {},
  async getLottoConfig(strLottoId) {
    let objRequest = {
      arrLottoIds: ["pick3", "pick4"],
    };
    let objResponse = await axios.post("/lottos-configs", objRequest);
    console.log(objResponse);

    return objResponse;
  },
};

export default utils;
