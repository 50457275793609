<template>
  <div>
    <!-- dir="rtl" -->
    <div
      :dir="getDir()"
      class="mainbody"
      :class="objUser && objUser.id ? 'logged_in' : 'notloggedin'"
    >
      <div class="maincolumns">
        <!-- {{ objUser }}{{ strToken }} -->

        <div class="contentdiv">
          <router-view></router-view>
        </div>

        <div class="sidebar" v-if="strToken">
          <Sidebar />
        </div>
      </div>
    </div>

    <div class="text-center">v{{ $utils.strVersionNo }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Sidebar from "../components/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
  computed: {
    ...mapGetters(["objUser", "strToken"]),
  },
  methods: {
    getDir() {
      let strLocale = this.$i18n.locale;
      if (strLocale == "fa") {
        return "rtl";
      } else {
        return "";
      }
    },
  },
};
</script>
<style></style>
